import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TemporaryDrawer } from './parts/Drawer/Drawer';
import './Header.scss';
import { getUser } from '../../../store/user/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { getCardInfo } from '../../../store/payment/payment';
import { Modal } from '../../controls';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IHeader {
  avatar: any;
  email: any;
  blockAccess: any;
}

const Header: FC<IHeader> = ({ avatar, email, blockAccess }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const globalAccess = useAppSelector(state => state.user.global_access);
  const companyName = useAppSelector((state) => state.user.companyName);
  const card = useAppSelector(state => state.payment.card);
  const isSubsidiary = useAppSelector((state) => state.user.isSubsidiary);

  const [selectedLink, setSelectedLink] = useState<string>('Profiles');
  const [modalAccess, setModalAccess] = useState<boolean>(false);
  const [drawOpened, setDrawOpened] = useState<object>({
    right: false,
  });

  useEffect(() => {
    dispatch(getUser());
    dispatch(getCardInfo());
  }, []);

  useEffect(() => {
    const isSeen = localStorage.getItem('isSeenBlockAccess');

    if (card === 'no card' && isSeen !== 'seen' && !isSubsidiary) {
      setModalAccess(true);
    }
  }, [card, isSubsidiary])

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setDrawOpened({ [anchor]: open });
    };

  const goToUserManagement = () => {
    setSelectedLink('Users Management');
    navigate('/users-management');
  };

  const goToProfiles = () => {
    setSelectedLink('Profiles');
    navigate('/dashboard');
  };

  const goToInvoicing = () => {
    setSelectedLink('Invoicing');
    navigate('/invoicing');
  };

  const goToReports = () => {
    setSelectedLink('Reports');
    navigate('/reports-list');
  };

  const goToLoads = () => {
    setSelectedLink('Loads');
    navigate('/loads');
  };

  return (
    <>
    <div className="header">
      <div className="header__logo">
        <img
          src={require('../../../assets/images/main-logo.png')}
          alt="Logo"
          className="header__logoImage"
        />
      </div>

      <div className="header__navLinks">
        <div
          className={`header__navLink ${
            location.pathname === '/dashboard' ? 'navLink_selected' : ''
          }`}
          onClick={() => goToProfiles()}
        >
          Profiles
        </div>
        {(!blockAccess && globalAccess && card && card !== 'no card') && (
          <div
            className={`header__navLink ${
              location.pathname === '/users-management' ||
              location.pathname === '/create-driver' ||
              location.pathname === '/edit-driver'
                ? 'navLink_selected'
                : ''
            }`}
            onClick={() => goToUserManagement()}
          >
            Users Management
          </div>
        )}
        <div
          className={`header__navLink ${
            location.pathname === '/loads' || location.pathname === '/loads/map'
              ? 'navLink_selected'
              : ''
          }`}
          onClick={() => goToLoads()}
        >
          Loads
        </div>
        <div
          className={`header__navLink ${
            location.pathname === '/invoicing' ? 'navLink_selected' : ''
          }`}
          onClick={() => goToInvoicing()}
        >
          Invoicing
        </div>
        {(globalAccess && card && card !== 'no card') && <div
          className={`header__navLink ${
            location.pathname === '/reports-list' ? 'navLink_selected' : ''
          }`}
          onClick={() => goToReports()}
        >
          Reports
        </div>}
      </div>

      <div className="header__userWindow">
        <div className="userWindow_icon">
          <img src={avatar} alt="" />
        </div>

        <div className="userWindow_data">
          <span className="secondary_text">
            Welcome <span className="userWindow_name">{companyName ? companyName : email ? email : 'User'}</span>
          </span>
          {/* <span className="secondary_text">
            FL#: <span className="userWindow_name">123456</span>
          </span> */}
        </div>

        <React.Fragment key={'drawer'}>
          <div className="userWindow_arrow" onClick={toggleDrawer('right', true)}></div>
          <TemporaryDrawer drawOpened={drawOpened} toggleDrawer={toggleDrawer} />
        </React.Fragment>
      </div>
    </div>

    <Modal
      open={modalAccess}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={() => {
        setModalAccess(false);
        navigate('/my-profile', {
          state: {
            tab: 'payment_details',
          },
        });
        localStorage.setItem('isSeenBlockAccess', 'seen')
      }}
      loading={false}
      confirmTitle={"ADD CARD"}
      modalName="customer-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p className='confirmation'>For full access, please go to the payment details section and add payment info.</p>

        </div>
      }
    />
    </>
  );
};

export default Header;
