import { useEffect, useState, useCallback, useRef } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerF,
  InfoWindow,
  DirectionsRenderer,
  OverlayView,
  Polyline,
} from '@react-google-maps/api';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'react-moment';
import { getSpecificLoad } from '../../../../../store/specificLoad/specificLoad';
import './ModalDriverMap.scss';
import { getFullName, getUniqueArray } from '../../../../../helpers';
import { getDriverHistory } from '../../../../../store/load/load';
import { toast } from 'react-toastify';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '100%',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function ModalDriverMap({ changeOpenModalMap, openModalMap, loadId }: any) {
  const { load } = useAppSelector(state => state.specificLoad);
  const customerPinImage = '../../../../../assets/images/icons/customer-point.png';
  const dispatch = useAppDispatch();
  const [center, setCenter] = useState<any>({
    lat: 40.745,
    lng: -74.523,
  });
  const [markers, setMarkers] = useState<any[]>([]);
  const [markersWithData, setMarkersWithData] = useState<any[]>([]);
  const [driverMarkers, setDriverMarkers] = useState<any[]>([]);
  const [driverMarkersForPolyline, setDriverMarkersForPolyline] = useState<any[]>([]);
  const [partsOfDriverMarkers, setPartsOfDriverMarkers] = useState<any[]>([]);
  const [partsOfDriverPointsForPolyline, setPartsOfDriverPointsForPolyline] = useState<
    any[]
  >([]);
  const [driver, setDriver] = useState<any>(null);
  const [directionsState, setDirectionsState] = useState<any>([]);
  const [path, setPath] = useState<any>(null);
  const [currentPin, seturrentPin] = useState<any>(null);
  const [currentDriverPin, seturrentDriverPin] = useState<any>(null);
  const [driverOpenedPins, setDriverOpenedPins] = useState<any>([]);
  const [zoomMap, setZoomMap] = useState<number>(16);
  const [idCurrentPin, setIdCurrentPin] = useState<string | null>('');
  const [idDriverCurrentPin, setIdDriverCurrentPin] = useState<string | null>('');
  const [isZoomChange, setIsZoomChange] = useState<boolean>(false);
  const [isLoaded_, setIsLoaded] = useState<any>(false);
  const [currInfoWindow, setCurrInfoWindow] = useState<any>(null);
  const [driverDataWithCustomer, setDriverDataWithCustomer] = useState<[]>([]);

  const mapRef = useRef<any>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB_BILzV8xNVsvMnxSJ13GsNaaoS0LVIeY',
  });

  useEffect(() => {
    dispatch(getSpecificLoad(loadId));
  }, [loadId]);

  const getOptions = (color: string, paths: any) => {
    return {
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 2.5,
      fillColor: color,
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      paths: paths,
      zIndex: 20000,
    };
  };

  useEffect(() => {
    // console.log(driverOpenedPins, 'driverOpenedPins')
  }, [driverOpenedPins]);

  const handleClickOpen = () => {
    changeOpenModalMap(true);
  };
  const handleClose = () => {
    setMarkers([]);
    setDriverMarkers([]);
    setDriver(null);
    changeOpenModalMap(false);
  };

  const containerStyle = {
    width: '100%',
    height: '70vh',
  };

  useEffect(() => {
    if (loadId) {
      dispatch(getDriverHistory({ loadId })).then(res => {
        if (res.type === 'getTrackingHistory/fulfilled') {
          if (res.payload.resource?.length > 0) {
            const driverArray: any = [];
            const driversIds: any = [];
            const tripParts: any = [];
            const driverData: any = [];

            res.payload.resource.map((point: any) => {
              driversIds.push(point.driver_id);
              driverArray.push({
                lat: point.lat,
                lng: point.lon,
                date: point.updated_at,
                id: point.id,
                driverId: point.driver_id,
                customerId: point.tag ? point.tag.replace(/\D/g, "") : null,
                timestamp: point.timestamp ?? null
              });

              if (point.tag) {
                driverData.push({
                  customerId: point.tag.replace(/\D/g, ""),
                  timestamp: point.timestamp,
                })
              }
            });

            const uniqueDriversIds = getUniqueArray(driversIds);
            uniqueDriversIds.map((driverId: any, index: number) => {
              const nextId: any = uniqueDriversIds[index + 1];
              const trip = driverArray.filter(
                (point: any) => point.driverId === driverId,
              );

              if (nextId) {
                const nextTrip = driverArray.filter(
                  (point: any) => point.driverId === nextId,
                );

                if (nextTrip[0]) trip.push(nextTrip[0]);
              }

              tripParts.push(trip);
            });

            setPartsOfDriverMarkers(tripParts);
            setDriverMarkers(driverArray);
            setDriverDataWithCustomer(driverData);
          }
        }
      });
    }
  }, [loadId]);

  useEffect(() => {
    if (driverDataWithCustomer.length && markers.length) {
      const markers_ = markers.map((marker) => {
        const driverDataItem: any = driverDataWithCustomer.filter((item: any) => +item.customerId === +marker.customerId);
        if (driverDataItem.length) {
          return {
            ...marker,
            timestamp: driverDataItem[0]?.timestamp ?? null
          }
        } else {
          return marker;
        }
      })

      if (markers[0]?.loadId === loadId && markers.filter(marker => !marker.timestamp).length === markers.length) {
        setMarkers(markers_);
      }
    }
  }, [driverDataWithCustomer, markers])

  useEffect(() => {
    setMarkers([])
  }, [])

  useEffect(() => {
    const array: any = [];

    if (load && load.data && load.data.resource) {
      if (!load.data.resource.last_track && load.data.resource.id === loadId) {
        toast.info('The tracking data is not available for this load.');
        handleClose();
      }
      let driverName = ' - ';

      if (load?.data?.resource?.driver) {
        driverName = getFullName(
          load?.data?.resource?.driver?.profile?.first_name,
          load?.data?.resource?.driver?.profile?.last_name,
        );
      } else if (load?.data?.resource?.guest_driver) {
        driverName = load?.data?.resource?.guest_driver?.name;
      }
      setDriver({
        track: load?.data?.resource.driver
          ? load?.data?.resource?.driver?.profile?.truck_number
          : ' - ',
        lastTrack: load?.data?.resource?.last_track
          ? {
              lat: load?.data?.resource?.last_track?.lat
                ? load?.data?.resource?.last_track?.lat
                : null,
              lng: load?.data?.resource?.last_track?.lon
                ? load?.data?.resource?.last_track?.lon
                : null,
            }
          : null,
        name: driverName,
      });
      //================ for receivers =========================

      load?.data?.resource.receivers.forEach((res: any) => {
        const newMarker = {
          truck: load?.data?.resource?.truck?.number
            ? load?.data?.resource?.truck?.number
            : ' - ',
          treiler: load?.data?.resource?.trailer?.number
            ? load?.data?.resource?.trailer?.number
            : ' - ',
          name: driverName,
          city: res?.customer?.address?.city ? res.customer.address.city : ' - ',
          load: load?.data?.resource.load_number,
          lat: res?.customer?.address?.lat ? res.customer.address.lat : ' - ',
          lng: res?.customer?.address?.lon ? res.customer.address.lon : ' - ',
          date: res?.date ? res.date : '',
          id: res.id + '-receiver',
          phone: res?.customer?.address?.phone ? res.customer.address.phone : ' - ',
          status: load?.data?.resource.status,
          customerId: res.id,
          loadId: load?.data?.resource.id,
        };
        if (res?.customer?.address?.lat && res?.customer?.address?.lon !== null) {
          array.push(newMarker);
        }
      });

      // //================ for shippers =========================
      load.data.resource.shippers.forEach((ship: any) => {
        const newMarker = {
          truck: load?.data?.resource?.truck?.number
            ? load?.data?.resource?.truck?.number
            : ' - ',
          treiler: load?.data?.resource?.trailer?.number
            ? load?.data?.resource?.trailer?.number
            : ' - ',
          name: driverName,
          city: ship?.customer?.address?.city ? ship.customer.address.city : ' - ',
          load: load.data.resource.load_number,
          lat: ship?.customer?.address?.lat ? ship.customer.address.lat : ' - ',
          lng: ship?.customer?.address?.lon ? ship.customer.address.lon : ' - ',
          date: ship?.date ? ship.date : '',
          id: ship.id + '-shipper',
          phone: ship?.customer?.address?.phone ? ship.customer.address.phone : ' - ',
          status: load.data.resource.status,
          customerId: ship.id,
          loadId: load?.data?.resource.id,
        };
        if (ship?.customer?.address?.lat && ship?.customer?.address?.lon !== null) {
          array.push(newMarker);
        }
      });
    }

    const marks = array.map((step: any) => {
      return {
        lat: +step.lat,
        lng: +step.lng,
      };
    });
    setPath(marks);
    setMarkers(array);
  }, [load, loadId]);

  useEffect(() => {
    if (!window.google) {
      return;
    }

    const directionsService: any = new google.maps.DirectionsService();
    const row: any = [];
    if (driverMarkers && driverMarkers.length >= 1) {
      setCenter({
        lat: +driverMarkers[Math.floor(driverMarkers.length / 2)].lat,
        lng: +driverMarkers[Math.floor(driverMarkers.length / 2)].lng,
      });
    }

    if (driverMarkers && driverMarkers.length > 0 && driver) {
      let waypoints_: any = [];

      if (driverMarkers.length >= 0) {
        waypoints_ = driverMarkers
          .sort(function (a: any, b: any) {
            return a.id - b.id;
          })
          .map((point: any) => {
            return {
              location: new google.maps.LatLng(point.lat, point.lng),
              stopover: true,
            };
          });
      }

      setDriverMarkersForPolyline(waypoints_.map((waypoint: any) => waypoint.location));
    }
  }, [driverMarkers, markers, driver]);

  useEffect(() => {
    if (!window.google) {
      return;
    }

    if (driverMarkers && driverMarkers.length >= 2) {
      setCenter({
        lat: +driverMarkers[Math.floor(driverMarkers.length / 2)].lat,
        lng: +driverMarkers[Math.floor(driverMarkers.length / 2)].lng,
      });
    }
    if (partsOfDriverMarkers && partsOfDriverMarkers.length > 0 && driver) {
      let partsOfwaypoints_: any = [];

      partsOfwaypoints_ = partsOfDriverMarkers.map((part: any) => {
        return part.map((point: any) => new google.maps.LatLng(point.lat, point.lng));
      });

      setPartsOfDriverPointsForPolyline(partsOfwaypoints_);
    }
  }, [partsOfDriverMarkers, markers, driver]);

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
  };

  const onLoad = (polyline: any) => {
    // console.log('polyline: ', polyline);
  };

  useEffect(() => {
    if (idCurrentPin) setIsZoomChange(true);
    seturrentPin(markers.find(pin => pin.id === idCurrentPin));
  }, [idCurrentPin]);

  useEffect(() => {
    if (idDriverCurrentPin) setIsZoomChange(true);
    const currentMarker = driverMarkers.find(pin => pin.id === idDriverCurrentPin);

    if (currentMarker) {
      const geocoder: any = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(+currentMarker?.lat, +currentMarker?.lng);

      geocoder.geocode(
        {
          latLng: latlng,
        },
        function (results: any, status: any) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              seturrentDriverPin({
                ...currentMarker,
                address: results[1].formatted_address,
              });
              setDriverOpenedPins([
                ...driverOpenedPins,
                {
                  ...currentMarker,
                  address: results[1].formatted_address,
                },
              ]);
            }
          } else {
            seturrentDriverPin({
              ...currentMarker,
              address: ' - ',
            });
            setDriverOpenedPins([
              ...driverOpenedPins,
              {
                ...currentMarker,
                address: ' - ',
              },
            ]);
          }
        },
      );
    }
  }, [idDriverCurrentPin]);

  return (
    <div>
      {openModalMap && (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-modal-map"
          open={openModalMap}
          maxWidth="lg"
          className="data-modal-map"
        >
          <BootstrapDialogTitle id="customized-dialog-modal-map" onClose={handleClose} />
          <DialogContent dividers>
            {isLoaded &&
              driver &&
              // markers.length > 0 &&
              load &&
              driverMarkers.length > 0 &&
              driverMarkersForPolyline.length > 0 && (
                <GoogleMap
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: true,
                    fullscreenControl: false,
                    clickableIcons: false,
                  }}
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoomMap}
                  // ref={mapRef}
                >
                  {/* {driverMarkersForPolyline && driverMarkersForPolyline.length > 0 
                    ? <Polyline
                    onLoad={onLoad}
                    path={driverMarkersForPolyline}
                    options={getOptions('black', driverMarkersForPolyline)}
                  /> : null
                  } */}

                  {partsOfDriverPointsForPolyline &&
                  partsOfDriverPointsForPolyline.length > 0
                    ? partsOfDriverPointsForPolyline.map((part: any, index: number) => {
                        return (
                          <Polyline
                            onLoad={onLoad}
                            path={part}
                            options={getOptions(
                              partsOfDriverPointsForPolyline.length === 1 ||
                                partsOfDriverPointsForPolyline.length - 1 === index
                                ? 'black'
                                : '#514f4f',
                              part,
                            )}
                            key={`poly--${index}`}
                          />
                        );
                      })
                    : null}

                  {markers && currentPin ? (
                    <InfoWindow
                      onLoad={onLoad}
                      position={{
                        lat: +currentPin.lat ? +currentPin.lat : 0,
                        lng: +currentPin.lng ? +currentPin.lng : 0,
                      }}
                    >
                      <div className="container-infoWindow">
                        <div className="click-listner"></div>
                        <div className="wrap-info">
                          <p className="wrap-info_tittle">{currentPin.city}</p>
                          <div className="wrap-info_subtitle">ETA</div>
                          <div className="wrap-info_table">
                            <p>
                              <span>Date:</span>
                              <span>
                                {currentPin.date ? (
                                  <Moment format="MM/DD/YYYY">{currentPin.date}</Moment>
                                ) : (
                                  ' - '
                                )}
                              </span>
                            </p>
                            <p>
                              <span>Time:</span>
                              <span>
                                {currentPin.date ? (
                                  <Moment format="hh:mm A">{currentPin.date}</Moment>
                                ) : (
                                  ' - '
                                )}
                              </span>
                            </p>
                            <p>
                              <span>Driver:</span>
                              <span>{currentPin.name}</span>
                            </p>
                            <p>
                              <span>Truck #:</span>
                              <span>{currentPin.truck ? currentPin.truck : ' - '}</span>
                            </p>
                            <p>
                              <span>Trailer #:</span>
                              <span>
                                {currentPin.treiler ? currentPin.treiler : ' - '}
                              </span>
                            </p>
                            {currentPin.timestamp && <p style={{color: "#62cd33", marginTop: 20, textTransform: "initial"}}>
                              <span>Confirmed by driver on {moment(currentPin.timestamp).format("MMMM Do YYYY [at] h:mm A")}</span>
                            </p>}
                            {/* <p>
                          <span>Phone #:</span>
                          <span> +{currentPin.phone}</span>
                        </p> */}
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  ) : null}
                  {markers && markers.length > 0 && driver && path && path.length > 0
                    ? markers.map((info: any) => {
                        return (
                          <OverlayView
                            position={{
                              lat: +info.lat ? +info.lat : 0,
                              lng: +info.lng ? +info.lng : 0,
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            key={info.id}
                          >
                            <img
                              src={require('../../../../../assets/images/icons/customer-point.png')}
                              width="24px"
                              height="24px"
                              onClick={() =>
                                setIdCurrentPin(idCurrentPin =>
                                  idCurrentPin === info.id ? '' : info.id,
                                )
                              }
                            />
                          </OverlayView>
                        );
                      })
                    : null}

                  {driverMarkers &&
                    driverOpenedPins.length &&
                    driverOpenedPins.map((pin: any) => {
                      return (
                        <InfoWindow
                          onLoad={onLoad}
                          position={{
                            lat: +pin.lat ? +pin.lat : 0,
                            lng: +pin.lng ? +pin.lng : 0,
                          }}
                          key={pin.id}
                          zIndex={currInfoWindow === pin.id ? 10000 : 8000}
                        >
                          <div
                            className="container-infoWindow"
                            onClick={() => setCurrInfoWindow(pin.id)}
                          >
                            <div className="click-listner"></div>
                            <div className="wrap-info">
                              <div className="wrap-info_table">
                                <p>
                                  <span>Date/Time:</span>
                                  <span>
                                    {pin.date ? (
                                      <Moment format="MM/DD/YYYY hh:mm A">
                                        {pin.date}
                                      </Moment>
                                    ) : (
                                      ' - '
                                    )}
                                  </span>
                                </p>

                                <p>
                                  <span>Address:</span>
                                  <span>
                                    {pin.address ? pin.address : ' - '}
                                    {/* {currentDriverPin.address} */}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      );
                    })}

                  {/* {driverMarkers && driverMarkers.length > 0
                    ? driverMarkers.map((info: any, index: number) => {
                        return (
                          <Marker
                            onLoad={onLoad}
                            onClick={() =>
                              setIdDriverCurrentPin(idDriverCurrentPin =>
                                idDriverCurrentPin === info.id ? '' : info.id,
                              )
                            }
                            key={info.id}
                            position={{
                              lat: +info.lat ? +info.lat : 0,
                              lng: +info.lng ? +info.lng : 0,
                            }}
                            title="custom-mark"
                            icon={{
                              url: '../../../../../assets/images/icons/point.png',
                              scale: 7,
                            }}
                          />
                        );
                      })
                    : null} */}

                  {driverMarkers && driverMarkers.length > 0
                    ? driverMarkers.map((info: any, index: number) => {
                        if (info.customerId) {
                          return (
                            <OverlayView
                              position={{
                                lat: +info.lat ? +info.lat : 0,
                                lng: +info.lng ? +info.lng : 0,
                              }}
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              key={info.id}
                            >
                              <div className="withTimestamp">
                                {info.timestamp && <span className="timestampWrapper">{moment(info.timestamp).format("LLL")}</span>}
                              <img
                                src={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? require('../../../../../assets/images/icons/point-checked.png')
                                    : require('../../../../../assets/images/icons/point-checked-grey.png')
                                }
                                width={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? '21px'
                                    : '18px'
                                }
                                height={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? '21px'
                                    : '18px'
                                }
                                onClick={() =>
                                  setIdDriverCurrentPin(idDriverCurrentPin =>
                                    idDriverCurrentPin === info.id ? '' : info.id,
                                  )
                                }
                              />
                              </div>
                            </OverlayView>
                          );
                        } else {
                          return (
                            <OverlayView
                              position={{
                                lat: +info.lat ? +info.lat : 0,
                                lng: +info.lng ? +info.lng : 0,
                              }}
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              key={info.id}
                            >
                              <img
                                src={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? require('../../../../../assets/images/icons/point.png')
                                    : require('../../../../../assets/images/icons/point-gray.png')
                                }
                                width={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? '21px'
                                    : '18px'
                                }
                                height={
                                  info.driverId ===
                                  driverMarkers[driverMarkers.length - 1].driverId
                                    ? '21px'
                                    : '18px'
                                }
                                onClick={() =>
                                  setIdDriverCurrentPin(idDriverCurrentPin =>
                                    idDriverCurrentPin === info.id ? '' : info.id,
                                  )
                                }
                              />
                            </OverlayView>
                          );
                        }

                        // return (
                        //   <Marker
                        //     onLoad={onLoad}
                        //     onClick={() =>
                        //       setIdDriverCurrentPin(idDriverCurrentPin =>
                        //         idDriverCurrentPin === info.id ? '' : info.id,
                        //       )
                        //     }
                        //     key={info.id}
                        //     position={{
                        //       lat: +info.lat ? +info.lat : 0,
                        //       lng: +info.lng ? +info.lng : 0,
                        //     }}
                        //     title="custom-mark"
                        //     icon={iconObj}
                        //   />
                        // );
                      })
                    : null}

                  {markers && markers.length > 0 && markers.length < 2 ? (
                    <Marker
                      onLoad={onLoad}
                      position={{
                        lat: +markers[0].lat ? +markers[0].lat : 0,
                        lng: +markers[0].lng ? +markers[0].lng : 0,
                      }}
                      title="custom-mark"
                      onClick={() =>
                        setIdCurrentPin(idCurrentPin =>
                          idCurrentPin === markers[0].id ? '' : markers[0].id,
                        )
                      }
                      icon={{
                        url: '../../../../assets/images/icons/point.png',
                        scale: 7,
                      }}
                    />
                  ) : null}
                  {driver && driver.lastTrack ? (
                    <>
                      <InfoWindow
                        onLoad={onLoad}
                        position={{
                          lat: +driver.lastTrack.lat ? +driver.lastTrack.lat : 0,
                          lng: +driver.lastTrack.lng ? +driver.lastTrack.lng : 0,
                        }}
                      >
                        <div className="wrap-info driver">
                          {/* <p>{driver.track ? driver.track : 'truck'}</p> */}
                          <p style={{padding: "1.5px 12px"}}>{driver.name}</p>
                        </div>
                      </InfoWindow>
                      {/* <Marker
                        onLoad={onLoad}
                        position={{
                          lat: +driver.lastTrack.lat ? +driver.lastTrack.lat : 0,
                          lng: +driver.lastTrack.lng ? +driver.lastTrack.lng : 0,
                        }}
                        title="custom-mark"
                        icon={{
                          url: '../../../../assets/images/icons/point.png',
                          scale: 7,
                        }}
                      /> */}
                    </>
                  ) : null}
                </GoogleMap>
              )}
          </DialogContent>
        </BootstrapDialog>
      )}
    </div>
  );
}
