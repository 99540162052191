import { useEffect, useState, useCallback, useRef } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/global';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'react-moment';
import { getSpecificLoad } from '../../../../../store/specificLoad/specificLoad';
import './ModalMap.scss';
import { getFullName } from '../../../../../helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '100%',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function ModalMap({ changeOpenModalMap, openModalMap, loadId }: any) {
  const { load } = useAppSelector(state => state.specificLoad);
  const dispatch = useAppDispatch();
  const [center, setCenter] = useState<any>({
    lat: 40.745,
    lng: -74.523,
  });
  const [markers, setMarkers] = useState<any[]>([]);
  // const [map, setMap] = useState<any>(null);
  const [driver, setDriver] = useState<any>(null);
  const [directionsState, setDirectionsState] = useState<any>([]);
  // const [pathMatrix, setPathMatrix] = useState<any>([]);
  const [path, setPath] = useState<any>(null);
  // const [sortedList, setSortedList] = useState<any[]>([]);
  const [currentPin, seturrentPin] = useState<any>(null);
  const [zoomMap, setZoomMap] = useState<number>(8);
  const [idCurrentPin, setIdCurrentPin] = useState<string | null>('');
  const [isZoomChange, setIsZoomChange] = useState<boolean>(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB_BILzV8xNVsvMnxSJ13GsNaaoS0LVIeY',
  });

  useEffect(() => {
    dispatch(getSpecificLoad(loadId));
  }, []);

  const handleClickOpen = () => {
    changeOpenModalMap(true);
  };
  const handleClose = () => {
    setMarkers([]);
    setDriver(null);
    changeOpenModalMap(false);
  };

  const containerStyle = {
    width: '100%',
    height: '70vh',
  };

  useEffect(() => {
    setMarkers([]);
    const array: any = [];
    if (load && load.data && load.data.resource) {
      let driverName = ' - ';

      if (load?.data?.resource?.driver) {
        driverName = getFullName(
          load?.data?.resource?.driver?.profile?.first_name,
          load?.data?.resource?.driver?.profile?.last_name,
        );
      } else if (load?.data?.resource?.guest_driver) {
        driverName = load?.data?.resource?.guest_driver?.name;
      }
      setDriver({
        track: load?.data?.resource?.driver
          ? load?.data?.resource?.driver?.profile?.truck_number
          : ' - ',
        lastTrack: load?.data?.resource?.last_track
          ? {
              lat: load?.data?.resource?.last_track?.lat
                ? load?.data?.resource?.last_track?.lat
                : null,
              lng: load?.data?.resource?.last_track?.lon
                ? load?.data?.resource?.last_track?.lon
                : null,
            }
          : null,
        name: driverName,
      });
      //================ for receivers =========================

      load?.data?.resource.receivers.forEach((res: any) => {

        const newMarker = {
          truck: load?.data?.resource?.truck?.number ? load?.data?.resource?.truck?.number : ' - ',
          treiler: load?.data?.resource?.trailer?.number ? load?.data?.resource?.trailer?.number : ' - ',
          name: driverName,
          city: res?.customer?.address?.city ? res.customer.address.city : ' - ',
          load: load?.data?.resource.load_number,
          lat: res?.customer?.address?.lat ? res.customer.address.lat : ' - ',
          lng: res?.customer?.address?.lon ? res.customer.address.lon : ' - ',
          date: res?.date ? res.date : "",
          id: res.id + '-receiver',
          phone: res?.customer?.address?.phone ? res.customer.address.phone : ' - ',
          status: load?.data?.resource.status,
        };
        if (res?.customer?.address?.lat && res?.customer?.address?.lon !== null) {
          array.push(newMarker);
        }
      });

      // //================ for shippers =========================
      load.data.resource.shippers.forEach((ship: any) => {
        const newMarker = {
          truck: load?.data?.resource?.truck?.number ? load?.data?.resource?.truck?.number : ' - ',
          treiler: load?.data?.resource?.trailer?.number ? load?.data?.resource?.trailer?.number : ' - ',
          name: driverName,
          city: ship?.customer?.address?.city ? ship.customer.address.city : ' - ',
          load: load.data.resource.load_number,
          lat: ship?.customer?.address?.lat ? ship.customer.address.lat : ' - ',
          lng: ship?.customer?.address?.lon ? ship.customer.address.lon : ' - ',
          date: ship?.date ? ship.date : "",
          id: ship.id + '-shipper',
          phone: ship?.customer?.address?.phone ? ship.customer.address.phone : ' - ',
          status: load.data.resource.status,
        };
        if (ship?.customer?.address?.lat && ship?.customer?.address?.lon !== null) {
          array.push(newMarker);
        }
      });
    }

    const marks = array.map((step: any) => {
      return {
        lat: +step.lat,
        lng: +step.lng,
      };
    });
    setPath(marks);
    setMarkers(array);
  }, [load, loadId]);

  useEffect(() => {
    if (!window.google) {
      return;
    }
    const directionsService = new google.maps.DirectionsService();
    const row: any = [];
    if (markers && markers.length >= 1) {
      setCenter({
        lat: +markers[0].lat,
        lng: +markers[0].lng,
      });
    }
    if (markers && markers.length > 1 && driver) {
      directionsService.route(
        {
          origin:
            driver && driver.lastTrack && driver.lastTrack?.lat && driver.lastTrack?.lng
              ? { lat: +driver.lastTrack?.lat, lng: +driver.lastTrack?.lng }
              : { lat: +markers[0]?.lat, lng: +markers[0]?.lng },
          destination: {
            lat: +markers[markers.length - 1].lat,
            lng: +markers[markers.length - 1].lng,
          },
          waypoints:
            markers.length >= 2
              ? markers.map((point: any) => {
                  return {
                    location: new google.maps.LatLng(point.lat, point.lng),
                  };
                })
              : [],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            row.push(result);
            setDirectionsState(row);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        },
      );
    }
  }, [markers]);
  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
  };
  const onLoad = (infoWindow: any) => {
    // console.log('infoWindow:=>>>> ', infoWindow);
  };
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (idCurrentPin) setIsZoomChange(true);
    seturrentPin(markers.find(pin => pin.id === idCurrentPin));
  }, [idCurrentPin]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-modal-map"
        open={openModalMap}
        maxWidth="lg"
        className="data-modal-map"
      >
        <BootstrapDialogTitle id="customized-dialog-modal-map" onClose={handleClose} />
        <DialogContent dividers>
          {isLoaded && driver && markers && load && (
            <GoogleMap
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                fullscreenControl: false,
              }}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoomMap}
              ref={mapRef}
            >
              {directionsState && markers && markers.length > 1
                ? directionsState.map((route: any, index: any) => {
                    return (
                      <DirectionsRenderer
                        key={index}
                        options={{
                          directions: route,
                          preserveViewport: isZoomChange,
                          polylineOptions: {
                            strokeColor: '#000',
                            strokeOpacity: 1,
                            strokeWeight: 4,
                          },
                          markerOptions: { icon: ' ' },
                        }}
                      />
                    );
                  })
                : null}
              {markers && currentPin ? (
                <InfoWindow
                  onLoad={onLoad}
                  position={{
                    lat: +currentPin.lat ? +currentPin.lat : 0,
                    lng: +currentPin.lng ? +currentPin.lng : 0,
                  }}
                >
                  <div className="container-infoWindow">
                    <div className="click-listner"></div>
                    <div className="wrap-info">
                      <p className="wrap-info_tittle">{currentPin.city}</p>
                      <div className="wrap-info_subtitle">ETA</div>
                      <div className="wrap-info_table">
                        <p>
                          <span>Date:</span>
                          <span>
                            {currentPin.date ? (
                              <Moment format="MM/DD/YYYY">{currentPin.date}</Moment>
                            ) : (
                              ' - '
                            )}
                          </span>
                        </p>
                        <p>
                          <span>Time:</span>
                          <span>
                            {currentPin.date ? (
                              <Moment format="hh:mm A">{currentPin.date}</Moment>
                            ) : (
                              ' - '
                            )}
                          </span>
                        </p>
                        <p>
                          <span>Driver:</span>
                          <span>{currentPin.name}</span>
                        </p>
                        <p>
                          <span>Truck #:</span>
                          <span>{currentPin.truck ? currentPin.truck : ' - '}</span>
                        </p>
                        <p>
                          <span>Trailer #:</span>
                          <span>{currentPin.treiler ? currentPin.treiler : ' - '}</span>
                        </p>
                        {/* <p>
                          <span>Phone #:</span>
                          <span> +{currentPin.phone}</span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              ) : null}
              {markers && markers.length > 0 && driver && path && path.length > 0
                ? markers.map((info: any) => {
                  
                    return (
                      <Marker
                        onLoad={onLoad}
                        onClick={() =>
                          setIdCurrentPin(idCurrentPin =>
                            idCurrentPin === info.id ? '' : info.id,
                          )
                        }
                        key={info.id}
                        position={{
                          lat: +info.lat ? +info.lat : 0,
                          lng: +info.lng ? +info.lng : 0,
                        }}
                        title="custom-mark"
                        icon={{
                          url: '../../../../assets/images/icons/point.png',
                          scale: 7,
                        }}
                      />
                    );
                  })
                : null}

              {markers && markers.length > 0 && markers.length < 2 ? (
                <Marker
                  onLoad={onLoad}
                  position={{
                    lat: +markers[0].lat ? +markers[0].lat : 0,
                    lng: +markers[0].lng ? +markers[0].lng : 0,
                  }}
                  title="custom-mark"
                  onClick={() =>
                    setIdCurrentPin(idCurrentPin =>
                      idCurrentPin === markers[0].id ? '' : markers[0].id,
                    )
                  }
                  icon={{
                    url: '../../../../assets/images/icons/point.png',
                    scale: 7,
                  }}
                />
              ) : null}
              {driver && driver.lastTrack ? (
                <>
                  <InfoWindow
                    onLoad={onLoad}
                    position={{
                      lat: +driver.lastTrack.lat ? +driver.lastTrack.lat : 0,
                      lng: +driver.lastTrack.lng ? +driver.lastTrack.lng : 0,
                    }}
                  >
                    <div className="wrap-info driver">
                      {/* <p>{driver.track ? driver.track : 'truck'}</p> */}
                      <p>{driver.name}</p>
                    </div>
                  </InfoWindow>
                  <Marker
                    onLoad={onLoad}
                    position={{
                      lat: +driver.lastTrack.lat ? +driver.lastTrack.lat : 0,
                      lng: +driver.lastTrack.lng ? +driver.lastTrack.lng : 0,
                    }}
                    title="custom-mark"
                    icon={{
                      url: '../../../../assets/images/icons/point.png',
                      scale: 7,
                    }}
                  />
                </>
              ) : null}
            </GoogleMap>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
