import { Stack } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import { Button, Input } from '../../controls';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import './SignIn.scss';
import { useNavigate, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { fetchSignIn } from '../../../store/user/user';
import { useAppDispatch } from '../../../hooks/global';
import { API_URI } from '../../../constants/api';
import { toast } from 'react-toastify';

const SignIn: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);

    dispatch(
      fetchSignIn({
        username: data.userEmail.trim(),
        password: data.password.trim(),
      }),
    ).then(res => {
      if (res.type === 'signin/fulfilled') {
        setTimeout(() => {
          navigate('/dashboard');
        }, 1500);
      } else {
        setLoading(false);
      }
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const goToSignUp = () => {
    navigate('/sign-up');
  };

  const goToResetPassword = () => {
    navigate('/recovery-password');
  };

  const onHandleLoginWithFB = async () => {
    localStorage.setItem('social_login_provider', 'facebook');
    window.open(`${API_URI}auth/redirect-facebook`, "_self");
  };

  const onHandleLoginWithGoogle = async () => {
    localStorage.setItem('social_login_provider', 'google');
    window.open(`${API_URI}auth/redirect-google`, "_self");
  };

  const handleFetchError= (error: any) => {
    console.error('Error: ', error);
  }

  useEffect(() => {
    if (location && location?.search && location?.search?.length) {
      const codeArray = location?.search?.split('=');
      if (codeArray[1]) {
        getSocialAccessToken(codeArray[1]);
      }
    }
  }, [location])

  const provider = localStorage.getItem('social_login_provider');

  const getSocialAccessToken = async (code: any) => {
    try {
      const response = await fetch(`${API_URI}social-access-token?code=${code}&provider=${provider}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'App-Key': '364b34b1745f1df62ad922ce58f3f0', }
      });
      
      const result = await response.json();
      localStorage.setItem('social_access_token', result?.data?.resource?.access_token)
      socialLogin(result?.data?.resource?.access_token);
    } catch (error) {
      handleFetchError(error);
    }
  }

  const socialLogin = async (token: any) => {
    try {
      const response = await fetch(`${API_URI}social-login?access_token=${token}&provider=${provider}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'App-Key': '364b34b1745f1df62ad922ce58f3f0', }
      });
      
      const result = await response.json();
      const stateObj: any = {
        fromSocial: true,
      };

      if (result?.message && result?.message?.length) {
        toast.info(result?.message);
      }

      if (result?.data?.resource && result?.data?.resource?.length && result?.data?.resource?.includes("@")) {
        stateObj.emailFromSocial = result?.data?.resource
      }

      if (result?.data?.resource?.credentials) {
        const userData = {
          first_name: result?.data?.resource?.profile?.first_name,
          last_name: result?.data?.resource?.profile?.last_name,
          email: result?.data?.resource?.email,
          role: result?.data?.resource?.role?.alias,
          company_name: result?.data?.resource?.entity?.owner?.title,
          is_profile_filled: result?.data?.resource.entity.owner.is_profile_filled,
          creator_id: result?.data?.resource?.entity.creator_id,
          profile_id: result?.data?.resource?.profile_id,
          id: result?.data?.resource?.id,
        };
  
        localStorage.setItem('token', result?.data?.resource?.credentials?.access_token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('refresh_token', result?.data?.resource?.credentials?.refresh_token);
        localStorage.setItem('owner_id', JSON.stringify(result?.data?.resource?.entity?.owner_id));
        localStorage.setItem('entity_id', JSON.stringify(result?.data?.resource?.entity?.id));
        navigate('/dashboard');
      } else {
        navigate('/sign-up', {
          state: stateObj
        });
      }
    } catch (error: any) {
      handleFetchError(error);
      if (error?.message && error?.message?.length) {
        toast.info(error?.message);
        navigate('/sign-in');
      }
    }
  }

  return (
    <div className="signInContainer l-popup">
      <img
        src={require('../../../assets/images/main-logo.png')}
        alt="Logo"
        className="mainLogo"
      />

      <div className="signInView">
        <h1 className="signInTitle">Log In</h1>

        <div className="signInForm">
          <Stack spacing={1}>
            <Input
              id="userEmail"
              name="Email"
              type="text"
              className="textField"
              control={control}
              isError={Boolean(errors.userEmail)}
              required={true}
              minLength={1}
              maxLength={50}
              withoutSpaces={true}
              fromSignIn={true}
            />
            <Input
              name="Password"
              id="password"
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.password)}
              required={true}
              maxLength={50}
              formType="login"
              withoutSpaces={true}
              fromSignIn={true}
            />
          </Stack>

          {/* <div className={'rememberMe'}>
            <Checkbox />

            <span>Remember Me</span>
          </div> */}

          <div className="horizontalLine">
            <hr />
            <span>OR</span>
            <hr />
          </div>

          <div className="registrationBtns">
            {/* <Button
              title="Sign-In with Facebook"
              Icon={<FacebookRoundedIcon color="warning" />}
              onClick={() => onHandleLoginWithFB()}
              className="authBtn authBtn__fb"
              variant="outlined"
            /> */}
            <Button
              title="Sign-In with Google"
              Icon={<GoogleIcon color="primary" />}
              onClick={() => onHandleLoginWithGoogle()}
              className="authBtn"
              variant="outlined"
            />
            <Button
              title="LOGIN"
              onClick={handleSubmit(onSubmit)}
              className="authBtn authBtn__login"
              variant="contained"
              type="submit"
              id="login"
              loading={loading}
            />

            <div className="authLinks">
              <span onClick={goToResetPassword}>Forgot password?</span>
              <span onClick={goToSignUp}>Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
